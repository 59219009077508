import { ReactNode } from "react";
import { TooltipPlacement } from "antd/es/tooltip";
import ObscuredWrapper from "app/components/elements/wrappers/obscured_wrapper";
import { useCanAccessBB } from "app/hooks/useCanAccessBudgetBuilder";
import { useFreeTrials } from "app/utils/api/queries/user.query";
import userSlice from "app/store/user/user.slice";
import User from "app/utils/user";
import { IUser } from "app/store/types/user.types";
import modalsSlice from "app/store/modals/modals.slice";

const ObscuredBBWrapper = ({
    children,
    placement,
}: {
    children: ReactNode;
    placement?: TooltipPlacement;
}) => {
    const { toggleBudgetBuilderTrialModal } = modalsSlice((state) => state);
    const canAccessBB = useCanAccessBB();

    const { user } = userSlice((state) => state);
    const UserModule = User(user as IUser);

    const { data: bbFreeTrials } = useFreeTrials(
        {
            enabled: !UserModule.isPremiumUser(),
        },
        "budget_builder"
    );

    const freeTrial = bbFreeTrials?.length === 1 ? bbFreeTrials[0] : null;

    return (
        <ObscuredWrapper
            placement={placement}
            condition={canAccessBB}
            custom_cta={
                !freeTrial ? (
                    <span
                        role="link"
                        aria-hidden="true"
                        className="link-inline"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleBudgetBuilderTrialModal();
                        }}
                    >
                        Try it for free
                    </span>
                ) : undefined
            }
        >
            {children}
        </ObscuredWrapper>
    );
};

export default ObscuredBBWrapper;
