import PageWithLoader from "app/hoc/page_with_loader";
import "./index.scss";
import { Button, message, Rate, Select, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useListingReviews, useListingSummary, useVendorListings } from "app/utils/api/queries";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import URL from "app/constants/route_urls";

const AddReview = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [checkingPermission, setCheckingPermission] = useState(true);

    const { data: listing, isLoading, error, isError } = useListingSummary(params.pk || "");
    const { data: userListings, isLoading: userListingsLoading } = useVendorListings();
    const { data: reviews } = useListingReviews(params.pk || "");

    // Handle 404 error
    useEffect(() => {
        if (isError && error?.response.status === 404) navigate("/404");
    }, [error?.response.status, isError, navigate]);

    // Check if user owns the listing
    useEffect(() => {
        if (userListings && listing && userListings?.findIndex((l) => l.pk === listing.pk) !== -1) {
            message.error("You can't submit a review for a listing you own");
            navigate(URL.RACES);
        }

        setCheckingPermission(false);
    }, [listing, navigate, userListings]);

    return (
        <PageWithLoader
            isLoading={isLoading || userListingsLoading || !listing || checkingPermission}
        >
            <div className="add-review">
                <Typography.Title level={1}>Your review for {listing?.title}</Typography.Title>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                    Fields marked with (<span className="add-edit-race__required">*</span>) are
                    required
                </Typography.Text>

                <form className="add-review__form">
                    <div>
                        <Typography.Text className="ant-label" strong>
                            <span>Your comments</span> <span className="ant-label-required">*</span>
                        </Typography.Text>

                        <TextArea rows={6} />
                    </div>

                    <div>
                        <Typography.Text className="ant-label" strong>
                            <span>Your rating</span> <span className="ant-label-required">*</span>
                        </Typography.Text>

                        <div>
                            <Select
                                style={{ width: "100%" }}
                                options={[
                                    { label: <Rate value={1} disabled />, value: 1 },
                                    { label: <Rate value={2} disabled />, value: 2 },
                                    { label: <Rate value={3} disabled />, value: 3 },
                                    { label: <Rate value={4} disabled />, value: 4 },
                                    { label: <Rate value={5} disabled />, value: 5 },
                                ]}
                            />
                        </div>
                    </div>

                    <div className="add-review__form-cta">
                        <Button
                            type="primary"
                            loading={false}
                            disabled={false}
                            className="responsive-cta"
                        >
                            <span>Submit</span>
                        </Button>
                    </div>
                </form>
            </div>
        </PageWithLoader>
    );
};

export default AddReview;
