/* eslint-disable no-nested-ternary */
import userSlice from "app/store/user/user.slice";
import { BudgetType } from "app/types/budget/budget.types";
import XLSX from "xlsx";

export const useExportBudgetData = () => {
    const { user } = userSlice();

    return async ({
        data,
        includeHeadings,
        includeRegistered,
    }: {
        data: BudgetType | undefined;
        includeHeadings: boolean;
        includeRegistered: boolean;
    }) => {
        if (data?.items) {
            /* flatten objects */
            const items = data.items.map((row) => ({
                ...(includeHeadings && {
                    group: {
                        label: "Group",
                        value: row.category?.group,
                    },
                }),

                name: { label: "Item", value: row.category?.name as string },
                event: {
                    label: "Event",
                    value: row.event === null ? "All Events" : row.event?.name,
                },
                price: { label: "Price", value: +row.price },
                quantity_type: {
                    label: "Quantity Type",
                    value:
                        row.quantity_type === "participant"
                            ? "Per participant"
                            : row.quantity_type === "event"
                              ? "Per event"
                              : "Custom", // Adjusted quantity type
                },

                ...(includeRegistered && {
                    quantity_reg: {
                        label: "Quantity (Reg)",
                        value: row.quantity_curr,
                    },
                    total_reg: { label: "Total (Reg)", value: +row.total_curr },
                }),

                quantity_proj: { label: "Quantity (Proj)", value: +row.quantity_proj },
                total_proj: { label: "Total (Proj)", value: +row.total_proj },
            }));

            const rows = items.map((item) => {
                const transformedItem: {
                    [key: string]: any;
                } = {};

                // Iterate over the keys of each item and set key-value pairs
                Object.entries(item).forEach(([key, valueObj]) => {
                    transformedItem[key] = valueObj.value; // Assign value of each key
                });

                return transformedItem;
            });

            // Calculate totals
            const totalProj = rows.reduce((sum, row) => sum + (parseFloat(row.total_proj) || 0), 0);
            const totalReg = includeRegistered
                ? rows.reduce((sum, row) => sum + (parseFloat(row.total_reg) || 0), 0)
                : 0;

            // Add total row
            const totalRow = {
                name: "Total",
                ...(includeRegistered && { total_reg: totalReg }),
                total_proj: totalProj,
            };
            rows.push(totalRow);

            const labelsSet: Set<string> = new Set();
            items.forEach((item) => {
                // Use Object.values to get all label-value pairs and extract the labels
                Object.values(item).forEach((valueObj) => {
                    labelsSet.add(valueObj.label); // Add label to the Set to ensure uniqueness
                });
            });
            const labels = Array.from(labelsSet);

            /* generate worksheet and workbook */
            const worksheet = XLSX.utils.json_to_sheet(rows);
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Items");

            /* fix headers */
            XLSX.utils.sheet_add_aoa(worksheet, [labels], { origin: "A1" });

            /* calculate column widths */
            // The minimum numbers are the letter count of column headers (e.g. name = 4)
            // Added a specific adjustment for the "name" column width to ensure it fits the content
            const colWidths = labels.map((label) => {
                const headerWidth = label.length;
                const maxContentWidth = Math.max(
                    ...rows.map((row) => {
                        const key = label.toLowerCase().replace(/\s|\(|\)/g, "_");
                        const contentWidth = row[key] ? row[key].toString().length : 0;

                        // Ensure the "Item" column has a minimum width of 20 characters
                        if (label === "Item") {
                            return Math.max(contentWidth, 20);
                        }

                        // Add extra width for numeric columns to accommodate commas and currency symbols
                        if (["Price", "Total (Reg)", "Total (Proj)"].includes(label)) {
                            return Math.max(contentWidth + 6, headerWidth); // Extra space for $ and commas
                        }

                        return contentWidth;
                    })
                );
                return { wch: Math.max(headerWidth, maxContentWidth) };
            });

            worksheet["!cols"] = colWidths;

            // Format the numeric columns with currency and commas
            // const formatCurrency = '"$"#,##0.00';
            const formatCurrency = `"${user.location?.ccy_symbol}"#,##0.00`; // E.g. "USD"#,##0.00
            const numberColumns = {
                price: "Price",
                total_reg: "Total (Reg)",
                total_proj: "Total (Proj)",
            };

            // Loop through numeric columns and apply currency format
            Object.entries(numberColumns).forEach(([colKey, labelName]) => {
                const colIndex = labels.findIndex((label) => label === labelName);

                if (colIndex !== -1) {
                    const range = XLSX.utils.decode_range(worksheet["!ref"]);
                    for (let R = 1; R <= range.e.r; ++R) {
                        const cellAddress = XLSX.utils.encode_cell({ r: R, c: colIndex });
                        if (!worksheet[cellAddress]) continue;
                        worksheet[cellAddress].t = "n"; // Set cell type to 'number'
                        worksheet[cellAddress].z = formatCurrency; // Apply XLSX currency format
                    }
                }
            });

            /* create an XLSX file */
            XLSX.writeFile(
                workbook,
                `Budget Builder - ${data.race.name} - ${new Date().toISOString().split("T")[0]}.xlsx`,
                { compression: true }
            );
        }
    };
};
